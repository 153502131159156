.formLabel {
  margin-bottom: 2px !important;
  margin-top: 10px;
}

/* Mobile */
@media all and (max-width: 768px) {
  .Login {
    padding: 20px;
  }
}

/* Desktop */
@media all and (min-width: 768px) {
  .Login {
    padding: 60px 0;
  }

  .Login form {
    margin: 0 auto;
    max-width: 320px;
  }

  .forgotPasswordButton{
    margin-top: 10px;
    margin-left: 20px;
  }

}