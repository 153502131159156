.formLabel {
  margin-bottom: 2px !important;
  margin-top: 10px;
}

/* Mobile */
@media screen and (max-width: 768px) {
  .ant-form-item-required{
    margin-bottom: -10px !important;
    margin-top: -10px !important;
  }

  .Signup {
    padding: 20px;
  }
}

/* Desktop */
@media all and (min-width: 768px) {
  .Signup {
    padding: 60px 0;
  }

  .Signup form {
    margin: 0 auto;
    max-width: 320px;
  }
}



