/* Mal.css */
.page-background {
    background-color: #F5F5F5; /* Replace with your desired color */
    height: 100vh; /* Make the section full height */
  }
  
.custom-small-button {
    padding: 2px 8px;        /* Adjust the padding to make the button smaller */
    font-size: 12px;          /* Reduce font size */
    height: 28px;             /* Control height to make the button smaller */
    line-height: 1;           /* Adjust line height to match the smaller size */
  }

.custom-default-button {
    padding: 6.4px 15px;        /* Adjust the padding to make the button smaller */
    font-size: 14px;          /* Reduce font size */
    height: 32px;             /* Control height to make the button smaller */
    line-height: 1.5715;           /* Adjust line height to match the smaller size */
  }

  

/*scroll on body*/
body {
    height: 100vh; /* Full viewport height */
    margin: 0;
    overflow: auto; /* Enable scrolling on overflow */
  }
  
  .page-background {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    overflow: auto; /* Ensure the content overflows with scroll */
    height: 100vh; /* Fill the screen height */
  }
  

 /* ANT INPUT COMPONENT */

/* Default input component */
.ant-input {
    height: 32px; /* Default height for inputs */
    padding: 4px 11px; /* Default padding for inputs */
    font-size: 14px; /* Default font size for inputs */
    border-radius: 4px; /* Default border-radius for inputs */
    border: 1px solid #d9d9d9; /* Default border color */
  }
  
  /* Default textarea component */
  .ant-input-textarea {
    font-size: 14px; /* Default font size for textareas */
    border-radius: 4px; /* Default border-radius for textareas */
    border: 1px solid #d9d9d9; /* Default border color */
  }
  
  /* Default placeholder style */
  .ant-input::placeholder {
    color: #d9d9d9; /* Default placeholder color */
    opacity: 1;
  }
  
  /* Default affix wrapper for inputs with icons (e.g., search) */
  .ant-input-affix-wrapper {
    height: 32px; /* Default height for affix inputs */
    padding: 0 11px; /* Default padding */
    border-radius: 4px; /* Default border-radius */
    border: 1px solid #d9d9d9; /* Default border color */
  }
  
  /* Adjust inner padding for input within affix wrapper */
  .ant-input-affix-wrapper .ant-input {
    padding: 4px 0; /* Default inner padding for input in affix */
  }
  

  .custom-p {
    font-size: 46px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal !important; 
  }

  /* NOTIFICATIONS COMPONENT CUSTOMIZATIONS */
  
  .ant-list-item {
    padding: 8px 0; /* Adjust to make the list spacing match */
  }

  /* Custom scroll style */
.ant-list-items {
    overflow-y: auto;
  }
  
  
  /* Optional custom scroll bar styling for a smoother look */
  .ant-list-items::-webkit-scrollbar {
    width: 4px;
  }
  
  .ant-list-items::-webkit-scrollbar-thumb {
    background-color: #d9d9d9;
    border-radius: 2px;
  }

  /* Add padding to the badge wrapper */
.custom-badge-wrapper {
    padding-top: 4px;
    margin-right: 8px;
  }
  
  /* Customize the color and size of the dot */
  .custom-badge-wrapper .ant-badge-dot {
    width: 6px;
    height: 6px;
    background-color: #FF85C0;  /* Set the dot color to #FF85C0 */
  }
  


