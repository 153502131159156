

/* FONTS --------------------------------------------------------------- */ 

body {
  margin: 0;
  padding: 0;
  color: #333;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: "Open Sans", sans-serif !important;
  height: 100% !important;
}

.openSans {
  font-family: "Open Sans", sans-serif;
}

/* Base font size */
html {
  font-size: 16px;
  font-family: "Open Sans", sans-serif !important;
}

/* Font sizes for headings and body text */
h1 {
  font-size: 2.5rem; /* 40px */
}

h2 {
  font-size: 2rem; /* 32px */
}

h3 {
  font-size: 1.75rem; /* 28px */
}

h4 {
  font-size: 1.5rem; /* 24px */
}

h5 {
  font-size: 1.25rem; /* 20px */
}

h6 {
  font-size: 1rem; /* 16px */
}

body {
  font-size: 1rem; /* 16px */
  line-height: 1.5;
}

p {
  font-size: 1rem;
  /* margin-bottom: 1rem; */
}



/* GLOBAL COLORS ---------------------------------------------------------------------------------------------------------------- */ 

:root {
  /* Primary */
  --primary-default-color: #143B82;
  --primary-hover-color: #1B4EAD;
  --primary-active-color: #0E2957;

    /* Error */
  --error-default-color: #cc2900;
  --error-active-color: #921D00;

    /* Text */
  --secondary-text-color: #8590A1;

   /* Input */ 
   --input-color: #C7CDD4;

}


/*CUSTOMIZING ANT COMPONENTS ------------------------------------------------------------------------------------------------------ */


  /* CUSTOM BUTTONS ------------------------------------------------ */

      /* PRIMARY BUTTON STYLES */
      .primary-button {
        background-color: var(--primary-default-color) !important;
        color: white !important;
        border-width: 1px !important;
        border-color: var(--primary-default-color) !important;
        line-height: 1.5715 !important;
        border-radius: 100px !important;
      }

      /* SECONDARY BUTTON STYLES */
      .secondary-button {
        background-color: #ffffff !important;
        color: var(--primary-default-color) !important;
        border-width: 1px !important;
        border-color: #ffffff !important;
        line-height: 1.5715 !important;
        border-radius: 100px !important;
      }

      /* ERROR BUTTON STYLES */
      .error-button {
        background-color: #ffffff !important;
        color: var(--error-default-color) !important;
        border-width: 1px !important;
        border-color: #ffffff !important;
        line-height: 1.5715 !important;
        border-radius: 100px !important;
      }

      /* HOVER AND ACTIVE STATES */
      .primary-button:hover {
        background-color: var(--primary-hover-color) !important;
        color: white !important;
      }

      .primary-button:active {
        background-color: var(--primary-active-color) !important;
        color: white !important;
      }

      .secondary-button:hover {
        background-color: #ffffff !important;
        color: var(--primary-default-color) !important;
        border-color: var(--primary-default-color) !important;
      }

      .secondary-button:active {
        background-color: #ffffff !important;
        color: var(--primary-active-color) !important;
        border-color: var(--primary-active-color) !important;
      }

      .error-button:hover {
        background-color: #ffffff !important;
        color: var(--error-default-color) !important;
        border-color: var(--error-default-color) !important;
      }

      .error-button:active {
        background-color: #ffffff !important;
        color: var(--error-active-color) !important;
        border-color: var(--error-active-color) !important;
      }

      /* SIZE-SPECIFIC BUTTON STYLES */

      /* Default size */
      .button-size-default {
        padding: 6.4px 15px !important;
        font-size: 14px !important;
        height: 32px !important;
      }

      /* Large size */
      .button-size-large {
        padding: 8px 20px !important;
        font-size: 16px !important;
        height: 40px !important;
      }



  /* ---------------------------------------------------------------------- */



  /* ANT CHECKBOX COMPONENT */
    .ant-checkbox-inner {
      border: 1px solid #A7AFBB !important;
    }



      /* Customizing INPUT FIELD */
      .ant-input-affix-wrapper {
        display: flex;
        align-items: center; /* Ensures vertical alignment of content */
        padding: 0 !important;
        border-radius: 8px !important;
        border: 1px solid var(--input-color) !important;
        background-color: #ffffff;
      }
      
      .ant-input-affix-wrapper .ant-input {
        padding: 8px !important;
        height: 100%;
        border: none !important;
        display: flex;
        align-items: center;
      }
      
      .ant-input-affix-wrapper:focus-within {
        border-color: #40a9ff !important;
        box-shadow: 0 0 5px rgba(64, 169, 255, 0.5) !important;
      }

      .ant-input-prefix {
        display: flex;
        align-items: center; /* Vertically centers the icon */
        padding-left: 8px; /* Add spacing on the left of the icon */
      }

      .ant-input-suffix {
        display: flex;
        align-items: center; /* Vertically centers the icon */
        padding-right: 8px; /* Add spacing on the left of the icon */
      }

      .ant-input {
        border: 1px solid var(--input-color) !important;
        border-radius: 8px !important;
        padding: 8px !important;
        background-color: #ffffff !important;
      }
      
      .ant-input:focus {
        border-color: #40a9ff !important;
        box-shadow: 0 0 5px rgba(64, 169, 255, 0.5) !important;
      }

      
            /* Default to small inputs for desktop */
            .ant-input {
              font-size: 14px !important;
              padding: 8px !important;
            }
            .ant-input-affix-wrapper {
              padding: 0 !important; /* Reset the wrapper's padding */
            }
            
            .ant-input-affix-wrapper .ant-input {
              padding: 8px !important;
            }
      
            /* Apply large inputs for mobile */
            @media (max-width: 768px) {
              .ant-input {
                font-size: 14px !important;
                padding: 12px 16px !important;
              }
            }
            @media (max-width: 768px) {
              .ant-input-affix-wrapper .ant-input {
                padding: 12px 16px !important;
              }
            }
  


  /* ANT PICKER COMPONENT */
    .ant-picker {
      height: 40px !important;
      padding: 0 11px !important;
      font-size: 16px !important;
      border-radius: 8px !important;
      border: 1px solid !important;
      border-color: var(--input-color) !important;
    }
    
    .ant-picker-input > input {
      padding: 6.4px 15px !important;
      height: 40px !important;
      font-size: 16px !important;
    }
    
    .ant-picker-input > input::placeholder {
      color: #A7AFBB !important;
      opacity: 1 !important;
    }
    
    .ant-picker-range {
      height: 40px !important;
      border-radius: 8px !important;
      border: 1px solid !important;
      border-color: var(--input-color) !important;
    }
    
    .ant-picker-range .ant-picker-active-bar {
      height: 40px !important;
      opacity: 0 !important;
    }
    
    .ant-picker-range .ant-picker-input {
      padding: 0 11px !important;
    }
    
    .ant-picker-range .ant-picker-input > input {
      padding: 4px 0 !important;
      height: 40px !important;
      font-size: 16px !important;
    }
    
    .ant-picker .ant-picker-suffix .anticon,
    .ant-picker .ant-picker-clear .anticon {
      color: #A7AFBB !important;
    }
  



  /* ANT SELECT COMPONENT */
    .ant-select-selector {
      height: 40px !important;
      padding: 6.4px 15px !important;
      font-size: 16px !important;
      border-radius: 8px !important;
      border: 1px solidc in !important;
      border-color: var(--input-color) !important;
    }

    .ant-select-selection-placeholder {
      color: #A7AFBB !important;
      opacity: 1 !important;
    }

    .ant-select-selection-search-input::placeholder {
      color: #A7AFBB !important;
      opacity: 1 !important;
    }

  /* ANT TAG COMPONENT*/
  .ant-tag {
    border-radius: 100px;
  }
 
    /* Adjustments for different browser support */
    .ant-select-selection-placeholder:-ms-input-placeholder { /* Internet Explorer 10+ AND MICROSOFT EDGE */
        color: #A7AFBB !important;
    }
    .ant-select-selection-placeholder::-webkit-input-placeholder { /* WebKit browsers */
        color: #A7AFBB !important;
    }


/* CUSTOM SCROLLBARS on CHROME, SAFARI, EDGE */

/* Global scrollbar styling for all scrollable elements */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #EDF0F3;
  border-radius: 4px;
  border: 2px solid transparent;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #DEE2E6;
}

 



select.form-control,
textarea.form-control,
input.form-control {
  font-size: 1rem;
}
input[type="file"] {
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
