.calendarButton{
    margin-top: 10px;
}

.custom-event {
    background-color: #EDF0F3 !important;
    border: none; /* Optional: to remove the default border */
    color: black; /* Optional: to ensure the text color is readable */
}

.custom-event .fc-event-title-container {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 4px !important;
}

.custom-event .fc-event-subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: small;
    color: rgb(0, 0, 0);
    margin-left: 9px !important;
}

.custom-event .fc-event-tag {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 4px !important;
    padding-top: 4px !important;
}

.fc-toolbar-title{
    margin-left: 10px !important;
    margin-top: 0px !important;
    font-family: sans-serif;
    font-size: 22px !important;
}

.fc-toolbar-chunk{
    margin-right: 10px !important;
    margin-top: 10px !important;
    margin-bottom: -10px !important;
}

.fc-view-harness{
    margin-right: 10px !important;
    margin-left: 10px !important;
}

/* Mobile */
@media screen and (max-width: 768px) {
    .clinic-calendar-title {
        font-size: medium;
        font-weight: 600;
        color: rgb(150, 150, 150); /* Customize as needed */
        margin-top: 5px;
    }

    .Calendar{
        background-color: white;
        padding-top: 10px !important;
        /* padding-left: 20px !important;
        padding-right: 20px !important; */
    }

    .ant-layout-content{
        padding: 0px !important;
    }
}

/* Desktop */
@media screen and (min-width: 768px) {
    .clinic-calendar-title {
        font-size: medium;
        font-weight: 600;
        color: rgb(150, 150, 150);
        margin-top: 5px;
        margin-left: 15px !important;
        float: right;
    }

    .Calendar{
        background-color: white;
        padding-top: 12px !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .ant-layout-content{
        padding: 2dvi !important;
    }
}

