.Home .lander {
  padding: 0px 0;
  text-align: center;
}

.Home .lander h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}
.cuteButton{
  float:right;
  margin-top: -15px;
}

/* Targeting the placeholder */
.ant-select-selection-placeholder {
  text-align: left !important;
}

/* Targeting dropdown menu items */
.ant-select-dropdown-menu-item {
  text-align: left !important;
}

/* Targeting the selected item text */
.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-search-input {
  text-align: left !important;
}

/* Mobile */
@media screen and (max-width: 768px) {
  .lander {
    padding-left: 20px !important;
    padding-right: 20px !important;
    text-align: center;
  }
}

/* Desktop */
@media screen and (min-width: 768px) {
  .lander {
    padding: 0px 0;
    text-align: center;
  }
}

.cancelButton {
  height: 32px !important;
  padding: 0px 15px !important;
  font-size: 14px !important;
  border-radius: 100px !important;
}

.applyButton {
  height: 32px !important;
  padding: 0px 15px !important;
  font-size: 14px !important;
  border-radius: 100px !important;
}

/* CSS to change input size to default */
.ant-picker, /* Targets DatePicker */
.ant-select-selector { /* Targets Select input */
  height: 32px !important; /* Ant Design default size */
  font-size: 14px !important; /* Ant Design default font size */
  padding: 4px 11px !important; /* Adjust padding for default size */
}

.ant-picker-dropdown .ant-picker-panel-container .ant-picker-panels {   
  display: inline-flex;   
  animation: none;   
  flex-wrap: nowrap;   
  direction: ltr;   
  @media (max-width: 768px) 
  { display: inline-grid;} 
}