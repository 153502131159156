.ResetPassword .success {
    margin: 0 auto;
    text-align: center;
  }
  
  .ResetPassword .success .glyphicon {
    color: grey;
    font-size: 30px;
    margin-bottom: 30px;
  }

  .formLabel {
    margin-bottom: 2px !important;
    margin-top: 10px;
  }


/* Mobile */
@media all and (max-width: 768px) {
  .ResetPassword {
    padding: 20px;
  }
}

/* Desktop */
@media all and (min-width: 768px) {
  .ResetPassword {
    padding: 60px 0;
  }

  .ResetPassword form {
    margin: 0 auto;
    max-width: 320px;
  }

  .ResetPassword .success {
    max-width: 400px;
  }
}

