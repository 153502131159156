
.ant-picker-ok button {
    height: 30px;    /* Adjust height */
    width: 60px;    /* Adjust width */
    font-size: 12px; /* Adjust font size */
    padding: 5px 10px; /* Adjust padding for better click area */
}

.underline-on-hover {
    text-decoration: none; /* Default state */
}

.underline-on-hover:hover {
    text-decoration: underline; /* Hover state */
}

.floatLeft{
    float: left !important;
    margin-left: 0px !important;
}

/* NEW CSS THAT CHAT GAVE ME BUT IS NOT REFLECTING ON THE JS FILE */

    /* SOLUTION 1 NO MAX HEIGHT OF DROPDOWN */
    .custom-time-picker-dropdown .ant-picker-time-panel-column {
        max-height: none !important; /* Removes the max-height limit on each column */
    }
    
    .custom-time-picker-dropdown .ant-picker-time-panel {
        height: auto !important; /* Allows the panel to expand to fit all items */
        max-height: 800px !important;
        overflow: visible !important; /* Ensures there is no scrollbar */
    }

    /* SOLUTION 2: MAKE SCROLLBAR VISIBLE ON DEFAULT */
    .custom-time-picker-dropdown .ant-picker-time-panel {
        overflow-y: scroll !important; /* Forces the scrollbar to always be visible */
        max-height: 200px !important; /* Adjust to control the dropdown height */
        height: auto !important; /* Ensures the panel can expand */
    }




