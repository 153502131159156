.App {
    height: 100% !important;
}

.ant-layout-content{
    /* background: linear-gradient(180deg, #E9F3FF 0%, #ffffff 100%) !important; */
    padding: 0px !important;
}

/* Mobile */
@media screen and (max-width: 768px) {

    .mainPage {
        /* make sure that the main page can get longer, but has a min height to snap footer to bottom if page is shorter than 100vh */
        min-height: calc(100vh - 64px - 92px) !important;
        /* background: linear-gradient(180deg, #E9F3FF 0%, #ffffff 100%) !important; */
    }

    .menu-button {
        display: inline-block; /* Show the button on smaller screens */
    }

    .ant-menu-horizontal {
        display: none !important; /* Hide the horizontal menu on smaller screens */
    }

    /* Reverting back to original or different color after click */
    .ant-menu-item-selected, .ant-menu-item-selected a {
        background-color: transparent !important;
    }

    /* .ant-layout-content {
        padding-left: 0px !important;
        padding-right: 0px !important;
    } */
}

/* Fix Top right menu buttons when in desktop */
@media screen and (min-width: 768px) {

    .mainPage {
        /* make sure that the main page can get longer, but has a min height to snap footer to bottom if page is shorter than 100vh */
        min-height: calc(100vh - 64px - 40px) !important;
        /* background: linear-gradient(180deg, #E9F3FF 0%, #ffffff 100%) !important; */
    }

    /* .ant-layout-content {
        padding-left: 20px !important;
        padding-right: 20px !important;
    } */

    /* Targeting only the active (click) state */
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
    .ant-menu-item:active {
        background-color: rgb(99, 99, 99) !important; 
    }

    /* Reverting back to original or different color after click */
    .ant-menu-item-selected, .ant-menu-item-selected a {
        background-color: transparent !important;
    }

    /* Hide three dots overflow button */
    .ant-menu-overflow-item
    {
        opacity: 100 !important; 
        order: 0 !important; 
        height: 60px !important; 
        overflow-y: hidden !important; 
        pointer-events: auto !important; 
        position: relative !important;
    }
    
    /* Hide three dots overflow button */
    .ant-menu-overflow-item-rest{
        display: none !important;
    }

    .ant-menu-overflow {
        margin-right: -30px;
    }

    .ant-layout-sider .ant-menu-item-selected, 
    .ant-layout-sider .ant-menu-item-selected:hover {
        background-color: transparent !important;
    }

    /* Reverting back to original or different color after click */
    .ant-menu-item-selected, .ant-menu-item-selected a {
        background-color: transparent !important;
    }

    .ant-menu-item-selected,
    .ant-menu-item-selected:hover,
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: transparent !important;
    }

}

.hide {
    display: none;
}

.show {
    display: block;
}

/* use this to set the selected state of the calendar list item */
.custom-selected {
    color: #1890ff !important;
    /* /* background-color: #e6f7ff; */
}